export default {
//en
  en: {
    page_error: {
      pity: 'What a pity!',
      notfound: 'Page not found!',
      back: 'To main'
    },
    meta: {
      title: 'Mining Pools Stats — Most Profitable Mining Pools',
      description: "Pools Top — Сomparison of crypto mining pool profitability. Choose the best mining pool with our realtime stat."
    },
    main: {
      title: 'Rating of real profitability of mining pools',
      info: 'The same S19j 104TH manners work on each pool from the rating. Every day, the floors are paid by the BTC, and we calculate what kind of profitability the 1TH given by the equipment receives for each pool.',
      telegram_bot: "* If you want to receive pool's ratings on a schedule, subscribe to our telegram bot."
    },
    topnav: {
      rating: 'rating',
      faq: 'faq',
      about: 'about',
      roadmap: 'Roadmap',
      howitworks: 'How it works'
    },
    footer: {
      connect: "Link to us",
      mail: "Send an email",
      group: "Group Telegram",
      bot: "Telegram Bot",
      about: "About Us",
      reason: "We developed this resource for ourselves, but decided to give it to the public for three reasons:",
      p1: "We hope that the rating will be useful for miners.",
      p2: "Improvement of income and quality of service for miners. If rating will be used actively - pools will pay attention to this rating, they will be less greedy, reduce their commission and compete for the user by increasing the service.",
      p3: "Maybe we do not take into account some criteria, make wrong calculations.",
      thanks1: "We will be grateful if you can give a reasonable criticism of the service, tell us what else can be implemented, how to help the mining community.",
      thanks2: "Support the project:",
      donate: "show BTC-Wallet",
      addr_group: "https://t.me/pooolstop"
    },
    rating: {
      days: 'd',
      chart: 'Chart',
      rating: 'Rating',
      pool: 'Pool',
      profitability: 'Payrate',
      hashrate: 'avr. Hashrate, TH',
      income: 'avr. Payment, BTC',
      possible_profit: 'possible profit',
      small: 'short',
      full: 'detail',
      nodata1: 'We collect REAL statistics on the operation of equipment on pools, at the moment we have not accumulated data for this period.',
      nodata2: 'You can substitute a link to monitor your account and the period will be calculated for you, your schedule will also be built.',
      nodata3: 'As data accumulates, pools will appear.'
    },
    watcher: {
      info: 'To compare your return to the rating, take the external monitoring link from your pool account and add it to the form below.',
      add_btn: 'Add',
      remove_btn: 'Remove',
      add_placeholder: 'Enter your watcher link'
    },
    chart: {
      title: 'Profitability for {day} days',
      scale: 'Scale',
      difficulty: 'Difficulty'
    },
    faq: {
      title: 'FAQ',
      question1: {
        title: 'How it works?',
        p1: 'We collect data on a daily basis:',
        p2: '- Average hash rate in 24 hours',
        p3: '- Payment in 24 hours',
        p4: 'By the formula',
        p5: ' is The real rate at which the pool paid for 1 Terahash, received from the miner. Or in other words, the number of BTC paid by the pool for 1Th transferred to it by the miner.',
        p6: 'We form a database of Real Payrate for each day for all pools. By comparing the average Real Payrate (24h) for the last 7 days, 30 days, 90 days, 180 days, 365 days, we get a rating - which pool was more profitable for the miner in any of those periods.'
      },
      question2: {
        title: "Why is there no date on the graph? Why doesn't the graph start today?",
        p1: "If you check the box next to the pools you are interested in in the column Chart, then below you will see graphs where you can visually see the dynamics of pool profitability, compare several pools, compare the stability of the pools.",
        p2: 'The chart is based on the Real Payrate(24h) coefficients.',
        p3: "If on any day the miner of one of the pools did not work - this date does not participate in calculating the rating and graph for other pools.",
        p4: "Some pools provide a report on payments not immediately, but with a delay of 1-2 days, so the current and yesterday's dates are removed from the comparison and graph construction."
      },
      question3: {
        title: "How can I compare my miner with the rating? ",
        p1: "Often pools give special terms, such as sharing their commission with large accounts - in this case the yield 1Th must be different from the base, which we get. To check this, we have added an option to substitute link to external monitoring from your pool in rating. In the comparison, you can see the quantitative difference between your conditions in the pool from the base, as well as your yield is compared with other pools. The system will show how much you could earn or lose if you worked in other pools based on history. Some pools do not provide such links. If necessary, we will describe this article in more detail and provide links on how to get links to external monitoring from different pools."
      },
      question4: {
        title: "Why is Payrate falling",
        p1: "Real Payrate directly depends on the complexity of BTC network, with increasing complexity profitability decreases and vice versa. Also the more participants in BTC network the less profit per participant."
      },
    },
    roadmap : {
      title: "Roadmap",
      step: "Step {step}",
      completed: "{persent}% completed",
      release1: {
        title: '',
        p1: 'Profitability rating',
        p2: 'Connect TOP 10 pools',
        p3: 'Collection of statistics by the pool API',
        p4: 'Watcher link user',
        p5: 'Telegram bot'
      },
      release2: {
        p1: "Connection of new pools to ranking",
        p2: "Connection to pools via STRATUM server", 
        p3: "Collect statistics without contacting pool", 
        p4: "Seamless switching between pools",
        p5: "Cashback - transfer of referral fees to miners",
        p6: "User wallet",
        p7: "Personal account LVL1",
      },
      release3: {
        p1: "Automatic selection and switching to the most profitable pool",
        p2: "Mining Calculator",
        p3: "Monitor additional coins",
        p4: "Automatic selection of a profitable coin",
        p5: "ERP system for miner and hotel mining",
        p6: "Personal account LVL2",
        p7: "Adding to SOLO rating",
        p8: "Pool load and loss analysis"
      }
    }
  },
//russian
  ru: {
    page_error: {
      pity: 'What a pity!',
      notfound: 'Page not found!',
      back: 'To main'
    },
    page_error: {
      pity: 'Как жаль!',
      notfound: 'Страница не найдена!',
      back: 'На главную'
    },
    meta: {
      title: 'Рейтинг пулов для майнинга — Статистика майнинговых пулов',
      description: "Pools Top — Сравнение пулов для майнинга криптовалют по доходности. Выберете лучший пул для майнинга с помощью нашей актуальной статистики. "
    },
    main: {
      title: 'Рейтинг доходности майнинг пулов',
      info: 'На каждом пуле из рейтинга работают одинаковые майнеры S19j 104Тх. Ежедневно пулы выплачивают ВТС, а мы вычисляем какую доходность получает 1TH отданный оборудованием по каждому пулу.',
      telegram_bot: '* Если хотите получать рейтинг пулов по расписанию, то подпишитесь на наш '
    },
    topnav: {
      rating: 'рейтинг',
      faq: 'faq',
      about: 'о нас',
      roadmap: 'дорожная карта',
      howitworks: 'Как это работает'
    },
    footer: {
      connect: "Связь с нами",
      mail: "Написать на почту",
      group: "Группа Telegram",
      bot: "Telegram Bot",
      about: "О нас",
      reason: "Этот ресурс мы разрабатывали для себя, но решили дать его в паблик по трем причинам:",
      p1: "Мы надеемся что рейтинг будет полезен для майнеров.",
      p2: "Повышение дохода и качества сервиса для майнеров. Если рейтингом будут пользоваться активно - пулы начнут обращать внимание на этот рейтинг, будут меньше жадничать, снижать свои комиссии и конкурировать за пользователя через повышение сервиса.",
      p3: "Возможно мы не учитываем какие то критерии, делаем не верный расчет.",
      thanks1: "Будем признательны за обратную связь!",
      thanks2: "Поддержать проект: ",
      donate: "показать адрес BTC кошелька",
      addr_group: "https://t.me/L0P2Yh2zcSRhYTEy"
    },
    rating: {
      days: 'д',
      chart: 'График',
      rating: 'Рейтинг',
      pool: 'Пул',
      profitability: 'Payrate',
      hashrate: 'avr. Hashrate',
      income: 'avr. Payment',
      possible_profit: 'Возможная прыбыль',
      small: 'кратко',
      full: 'подробно',
      nodata1: 'Мы собираем РЕАЛЬНУЮ статистику работы оборудования на пулах, на данный момент данных за этот период мы не накопили.',
      nodata2: 'Вы можете подставить ссылку на мониторинг вашего аккаунта и период для вас будет расчитан, так же будет построен Ваш график.',
      nodata3: 'По мере накопления данных пулы будут появляться.'
    },
    watcher: {
      info: 'Чтобы сравнить Вашу доходность с рейтингом, возьмите ссылку для внешнего мониторинга с аккаунта вашего пула и добавьте ее в форму ниже.',
      add_btn: 'Добавить',
      remove_btn: 'Удалить',
      add_placeholder: 'Введите Ваш watcher link'
    },
    chart: {
      title: 'Прибыльность за {day} дней',
      scale: 'Маштаб',
      difficulty: 'Сложность'
    },
    faq : {
      title: 'Вопросы и ответы',
      question1: {
        title: 'Как это работает?',
        p1: 'Ежедневно мы собираем данные:',
        p2: ' - Средний хешрейт за 24 часа',
        p3: ' - Выплата за 24 часа',
        p4: 'По формуле ',
        p5: ' - Реальная ставка по которой пул оплатил полученный от майнера 1 Terahash. Или иначе количество ВТС которое пул оплатил за 1TH переданный ему майнером.',
        p6: 'Мы формируем базу Real Payrate за каждый день по всем пулам. Сравнивая среднее значение Real Payrate (24h) за последние 7 дней, 30 дней, 90 дней, 180 дней, 365 дней, мы получаем рейтинг - какой пул был прибыльнее для майнера на любом из этих периодов.'
      },
      question2: {
        title: 'Почему на графике нет какой-то даты? Почему график начинается не с сегоднешнего дня?',
        p1: 'Если поставить галочку напротив интересующих Вас пулов в столбце График (Chart) то внизу появится графики, на которых можно визуально увидеть динамику доходности пула, сравнить несколько пулов, сравнить стабильность пулов.',
        p2: 'График строится по коэффициентам Real Payrate(24h).',
        p3: 'Если в какой-то день майнер одного из пулов не работал - эта дата не участвует в вычислении рейтинга и построении графика и у остальных пулов.',
        p4: 'Некоторые пулы предоставляют отчет по выплатам не сразу, а с задержкой 1–2 дня, поэтому текущая и вчерашняя даты убираются из сравнения и построения графиков.'
      },
      question3: {
        title: 'Как сравнить свой майнер с рейтингом? ',
        p1: "Часто пулы дают специальные условия, например делятся с крупными аккаунтами своей комиссией - в этом случае доходность 1Тх должна отличаться от базовой, которую получаем мы. Чтобы это проверить мы добавили возможность подставлять в рейтинг ссылку на внешний мониторинг с вашего пула. В сравнении можно увидеть количественное отличие Ваших условий на пуле от базовых, так же ваша доходность сравнивается с другими пулами. Система на основании истории покажет сколько вы могли заработать или потерять если бы работали на других пулах. Некоторые пулы такие ссылки не предоставляют. Если это необходимо мы более подробно распишем эту статью и сделаем ссылки на то как забирать с разных пулов ссылки на внешний мониторинг."
      },
      question4: {
        title: 'Почему Payrate падает?',
        p1: 'Real Payrate напрямую зависит от сложности сети BTC, с увеличением сложности прибыльность падает и наоборот. Так же чем больше участников сети BTC тем меньше прибыль на одного участника.'
      },
    },
    roadmap : {
      title: "Разарботка проекта",
      step: "Шаг {step}",
      completed: "{persent}% завершено",
      release1: {
        title: '',
        p1: 'Рейтинг профитности',
        p2: 'Подключить ТOP 10 пулов',
        p3: 'Сбор статистики по API пула',
        p4: 'Watcher link пользователя',
        p5: 'Telegram bot'
      },
      release2: {
        p1: "Подключение новых пулов к рейтингу",
        p2: "Подключение к пулам через STRATUM server", 
        p3: "Сбор статистики без обращения на пул", 
        p4: "Бесшовное переключение между пулами",
        p5: "Кэшбэк - передача реферальных вознаграждений майнерам",
        p6: "Кошелек пользователя",
        p7: "Личный кабинет LVL1",
      },
      release3: {
        p1: "Автоматический выбор и переключение на самы прибыльный пул",
        p2: "Калькулятор майнинга",
        p3: "Мониторинг дополнительных мрнет",
        p4: "Автоматический выбор прибыльной монеты",
        p5: "ERP для майнера и майнинг отеля",
        p6: "Личный кабинет LVL2",
        p7: "Добавление в рейтинг СОЛО",
        p8: "Анализ нагрузки и потерь на пулах"
      }
    }
  },
  ch: {
    meta: {
      title: 'Mining Pools Stats — Most Profitable Mining Pools',
      description: "Pools Top — Сomparison of crypto mining pool profitability. Choose the best mining pool with our realtime stat."
    },
    main: {
      title: '评估矿池的实际盈利能力',
      info: '相同的 S19j 104TH 方式适用于排名中的每个池。 每天以 BTC 支付地板，我们计算设备给出的 1TH 为每个池获得多少收益。 ',
      telegram_bot: '* 如果您想按时收到泳池评分，请订阅我们的'
    },
    topnav: {
      rating: '评分',
      faq: '经常问的问题',
      about: '关于我们',
      roadmap: '路線圖',
      howitworks: 'Как это работает'
    },
    footer: {
      connect: "链接到我们 ",
      mail: "寄信 ",
      group: "Telegram group",
      bot: "Telegram Bot",
      about: "关于我们",
      reason: "我们为自己开发了这个资源，但出于三个原因决定分享它：",
      p1: "我们希望评级对矿工有用。 ",
      p2: "增加矿工的收入和服务质量。如果评级被积极使用，矿池会关注这个评级，减少贪婪，减少佣金并争夺用户，改善服务。",
      p3: "也许我们没有考虑一些标准，我们做出了错误的计算。 ",
      thanks1: "如果您能对服务提出合理的批评，告诉我们还有什么可以实施的，如何帮助采矿社区，我们将不胜感激。 ",
      thanks2: "支持项目：",
      donate: "BTC wallet",
      addr_group: "https://t.me/pooolstop"
    },
    rating: {
      days: 'd',
      chart: '日程',
      rating: '评分',
      pool: '水池',
      profitability: 'Payrate',
      hashrate: 'avr. Hashrate',
      income: 'avr. Payment',
      possible_profit: 'Возможная прыбыль',
      small: 'кратко',
      full: 'подробно',
      nodata1: 'We collect REAL statistics on the operation of equipment on pools, at the moment we have not accumulated data for this period.',
      nodata2: 'You can substitute a link to monitor your account and the period will be calculated for you, your schedule will also be built.',
      nodata3: 'As data accumulates, pools will appear.'  
    },
    watcher: {
      info: '要将您的回报与您的评级进行比较，请从您的矿池帐户中获取外部监控链接并将其添加到下面的表格中。 ',
      add_btn: '添加',
      remove_btn: 'Удалить',
      add_placeholder: '输入观察者链接'
    },
    chart: {
      title: 'Прибыльность за {day} дней',
      scale: '规模',
      difficulty: 'Difficulty'
    },
    faq : {
      title: '经常问的问题',
      question1: {
        title: '这个怎么运作 ',
        p1: 'Ежедневно мы собираем данные:',
        p2: ' 24 小时的平均算力',
        p3: ' 24 小时内付款',
        p4: '根据公式 ',
        p5: '其中 Real Payrate 其中 Real Payrate 是矿池为从矿工收到的 1 terahash 支付的实际费率。',
        p6: '或者换句话说，矿工给它的矿池为 1Th 支付的 BTC 数量。 我们每天为所有池形成实际工资率基础。比较过去 7 天、30 天、90 天、180 天、365 天的平均实际费率（24 小时），我们得到一个评级——在任何这些时期，哪个矿池对矿工来说更有利可图。 '
      },
      question2: {
        title: '为什么图表上没有日期？为什么日程今天没有开始？',
        p1: '如果您在图表列中选中您感兴趣的矿池旁边的框，那么您将在下面看到图表，您可以清楚地看到矿池盈利能力的动态，比较几个矿池，比较矿池的稳定性。 ',
        p2: '基于实际赔率（24 小时）赔率的图表。 ',
        p3: '如果某一天其中一个矿池的矿工没有工作，则该日期不包括在其他矿池的评级和时间表的计算中。 ',
        p4: '一些池不是立即提供付款报告，而是延迟 1-2 天，因此当前和昨天的日期从比较和图表中删除。 '
      },
      question3: {
        title: '如何将您的矿工与评级进行比较？观察者链接 ',
        p1: "矿池通常会给出特殊条件，例如，他们与大账户分享佣金——在这种情况下，第一收益应该与我们收到的基础收益不同。为了验证，我们在评级中添加了从您的池中替换外部监控链接的功能。相比之下，您可以看到您在池中的条件与基础之间的数量差异，以及将您的盈利能力与其他池进行比较。如果您根据历史在其他矿池中工作，系统将显示您可以赚取或损失多少。一些池不提供此类链接。如有必要，我们将更详细地描述本文，并提供有关如何从不同池中获取外部监控链接的链接。 "
      },
      question4: {
        title: '为什么工资会下降？ ',
        p1: '真实的 Payrate 直接取决于 BTC 网络的复杂性，随着复杂性的增加，盈利能力下降，反之亦然。 此外，BTC 网络中的参与者越多，每个参与者的利润就越少。 '
      },
    },
    roadmap : {
      title: "项目发展 ",
      step: "Шаг {step}",
      completed: "{persent}% 完成",
      release1: {
        title: '',
        p1: '盈利能力评级 ',
        p2: '连接 TOP 10 矿池 ',
        p3: '收集池 API 的统计信息 ',
        p4: '用户链接观察器',
        p5: 'Telegram bot'
      },
      release2: {
        p1: "将新池连接到排名",
        p2: "通过 STRATUM 服务器连接到池", 
        p3: "准确的算力测量", 
        p4: "现金返还 - 将推荐奖励转移给矿工",
        p5: "所有矿池的哈希率相同",
        p6: "从钱包接收支付数据",
        p7: "从多个位置监控连接质量",
        p8: "每个池的负载和损失分析"
      },
      release3: {
        p1: "用户帐号",
        p2: "采矿计算器",
        p3: "平衡器",
        p4: "增加 SOLO 评分",
        p5: "平稳过渡到最大利润池",
        p6: "额外监控硬币",
        p7: "自动切换和硬币选择",
        p8: "能够自动交换加密货币"
      }
    }
  }
}
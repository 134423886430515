const lang = localStorage.getItem('lang')
if (lang) {
  document.title = translations[lang].meta.title
}
import translations from '../../assets/translation' 

export default {
  state: lang ? { language: lang } : getLanguageByNavigator(),
  mutations: {
    setLanguage (state, value) {
      state.language = value
      document.title = translations[value].meta.title
      document.querySelector('meta[name="description"]').setAttribute("content", translations[value].meta.description)
      localStorage.setItem('lang', value)
    }
  },
  actions: {
    setRuLanguage ({ commit }) {
      commit('setLanguage', 'ru')
    },
    setEnLanguage ({ commit }) {
      commit('setLanguage', 'en')
    },
    setChLanguage ({ commit }) {
      commit('setLanguage', 'ch')
    }
  },
  getters: {
    language (state) {
      return state.language
    }
  }
}

function getLanguageByNavigator() {
  let language = 'en'
  if (/^ru\b/.test(window.navigator.language)) language = 'ru'
  if (/^en\b/.test(window.navigator.language)) language = 'en'
  if (/^zh\b/.test(window.navigator.language)) language = 'ch'
  document.title = translations[language].meta.title
  document.querySelector('meta[name="description"]').setAttribute("content", translations[language].meta.description)
  return { language: language }
}

import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/home')
  },
  {
    path: '/:lang',
    beforeEnter: (to, from, next) => {
      const { lang } = to.params
      if (lang == 'ru' || lang == 'en' || lang == 'ch' ) {
        if (!to.path.endsWith('/')) {
          next({path: lang + '/'})
        } else {
          next()
        }
      } else {
        next({name: 'All'})
      }
    },
    component: () => import('../views/home')
  },
  {
    path: '*',
    name: 'All',
    component: () => import('../views/page-error')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  let isHtml = to.path.endsWith('/index.html')
  let isPhp = to.path.endsWith('/index.php')
  if (isHtml || isPhp) {
    let index = isHtml ? to.path.lastIndexOf('/index.html') : to.path.lastIndexOf('/index.php')
    next({path: to.path.substring(0, index)})
  }
  next()
})

export default router
